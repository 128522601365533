import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Link } from 'gatsby'

const HelpSection = ({children}) => (
  <div className="help-section">
    {children}
  </div>
)

const HelpSectionHeader = ({step, title}) => (
  <div className="help-section-header has-text-primary">
    <span>{step}</span>
    <div className="title has-text-primary">{title}</div>
  </div>
)

const HelpSectionContent = ({children}) => (
  <div className="content">
    {children}
  </div>
)

const HelpSectionFooter = ({children}) => (
  <div className="help-section-footer">
    {children}
  </div>
)

class HelpPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>寻求帮助</title>
          </Helmet>
          <HelpSection>
            <HelpSectionHeader step="1" title="学习如何对待失眠" />
            <HelpSectionContent>
              <p>之所以陷入失眠是因为不懂得如何对待它，之所以恐惧失眠是因为不了解它。所以走出失眠的过程就是一个自我学习和自我努力的过程。我们不可能轻而易举的通过外部的药物和神奇的方法走出失眠，你必须去学习、去尝试、去坚持。睡吧在几年的时间里积累了不少资料与珍贵的经验，你可以通过阅读这些资料和分享了解如何对待失眠，并按照文章中的建议去尝试和坚持。</p>
              <p>在发起求助之前，阅读<Link to="/tutorial/">睡吧知识库</Link>和<Link to="/blog/5b1f6ce92864a50fb7a4a419">睡吧公众号</Link>的文章，学习和寻找的过程会让你受益终生。</p>
            </HelpSectionContent>
            <HelpSectionFooter>
              <Link to="/tutorial/" className="button is-primary is-outlined">睡吧知识库</Link>
              <Link to="/blog/5b1f6ce92864a50fb7a4a419" className="button is-primary is-outlined">睡吧公众号</Link>
            </HelpSectionFooter>
          </HelpSection>

          <HelpSection>
            <HelpSectionHeader step="2" title="填写睡眠评估" />
            <HelpSectionContent>
              <p>如果没法通过阅读资料找到自己失眠的原因，也不能明确的了解具体该如何做才能走出失眠。那就需要求助，但是每个人都应该通过正确的方式求助。许多人只是在抱怨一番并询问该怎么办？这样的提问无法得到任何答案。因为你所经历的痛苦只是一些表象，而我们无法通过一些表面现象的抱怨和哭诉找出失眠的原因。想要求助就需要填写睡眠评估，并发表在睡吧小组或者通过邮件发送。</p>
            </HelpSectionContent>
            <HelpSectionFooter>
              <Link to="/evaluate/login" className="button is-primary is-outlined">进行睡眠评估</Link>
            </HelpSectionFooter>
          </HelpSection>

          <HelpSection>
            <HelpSectionHeader step="3" title="正确提出问题" />
            <HelpSectionContent>
              <p>发表评估是正确的求助方式，每个人在发表评估之后都会得到一些意见。但是你一定会存有各种各样的疑问。我们一定要在自己的评估后面提出疑问，这能让帮助你的人参考之前的状态和之前的意见，也能让自己了解到进展。而且在提出这些疑问之前，一定要问自己几个问题：</p>
              <ul>
                <li>我的这个疑问是在抱怨还是在理智的求助？</li>
                <li>我是不是按照意见去做了？</li>
                <li>我有没有通过大家给出的意见反省自己？</li>
                <li>我这个问题是在试图解决造成失眠的原因，还是失眠的现象？</li>
              </ul>
              <p>事实上，许多疑问都已经在之前的意见中给出了解答，我们只是需要回顾之前的意见并反省自己。确保自己的问题有价值，只有有价值的问题才会让自己有所进步。另外，下面两个连接解答了许多常见的问题，提问之前先浏览一下有没有你想要的答案。</p>
            </HelpSectionContent>
            <HelpSectionFooter>
              <Link to="/category/5b1f1fde4403130e846798da" className="button is-primary is-outlined">常见问题</Link>
              <Link to="/category/5b1f1fd54403130e846798d9" className="button is-primary is-outlined">失眠误区</Link>
            </HelpSectionFooter>
          </HelpSection>

          <HelpSection>
            <HelpSectionHeader step="4" title="阅读睡吧书籍" />
            <HelpSectionContent>
              <p>睡吧出版的《乔装的失眠》非常详细和系统的告诉大家如何一步一步走出失眠。也是睡吧十几年经验和理念的沉淀。强烈建议每个失眠的人阅读。</p>
            </HelpSectionContent>
            <HelpSectionFooter>
              <a href="http://www.hellosleep.net/blog/6336edf75dcaa3173ab8a3a6" className="button is-primary is-outlined">阅读《乔装的失眠》</a>
            </HelpSectionFooter>
          </HelpSection>

          <HelpSection>
            <HelpSectionHeader step="5" title="帮助他人" />
            <HelpSectionContent>
              <p>『一个非常明显的事实是，当你只是想着自己的时候，你的整个心智会变得狭窄，这种狭隘的心灵会放大那些看似糟糕的事情，并且带给你恐惧、不安和无法抑制的痛苦。然而，当你开始同情和关爱其他人的时候， 你的心灵会变得宽广并开放，自己的问题会显得微不足道，你的感受会变得大不相同。』</p>
              <p>失眠只是一个普通的烦恼，而走出这个烦恼最好的方式就是关爱和帮助其他的人。</p>
            </HelpSectionContent>
            <HelpSectionFooter>
              <a href="http://www.douban.com/group/hellosleep/" className="button is-primary is-outlined">睡吧小组</a>
              <Link to="/donate/" className="button is-primary is-outlined">帮助睡吧</Link>
            </HelpSectionFooter>
          </HelpSection>
        </div>
      </Layout>
    )
  }
}

export default HelpPage
